import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'
import ErrorScreen from './errorScreen'

Bugsnag.start({
  apiKey: process.env.GATSBY_BUGSNAG_API_KEY,
  appVersion: process.env.GATSBY_BUILD_NAME || 'not-specified',
  releaseStage:
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development',
  enabledReleaseStages: ['production'],
  plugins: [new BugsnagPluginReact()],
})

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(
  React
)

export const wrapWithErrorBoundary = ({ element }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorScreen}>{element}</ErrorBoundary>
  )
}

export const withErrorBoundary = (boundaryProps) => (Component) => {
  const WithErrorBoundary = (props) => (
    <ErrorBoundary {...boundaryProps}>
      <Component {...props} />
    </ErrorBoundary>
  )

  WithErrorBoundary.displayName = `WithErrorBoundary(${getDisplayName(
    Component
  )})`

  return WithErrorBoundary
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}
