import { QueryClient, QueryClientProvider } from 'react-query'
import React from 'react'
import { Helmet } from 'react-helmet'

export default function Wrapper({ children }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      retry: 2,
    },
  })
  return (
    <>
      <Helmet>
        <script
          crossOrigin='anonymous'
          src='https://polyfill.io/v3/polyfill.min.js?features=default%2CResizeObserver&flags=gated'
        />
      </Helmet>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </>
  )
}

export const wrapElement = ({ element, props }) => (
  <Wrapper {...props}>{element}</Wrapper>
)
