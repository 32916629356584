import React, { useEffect } from 'react'
import useQueryParams from '../../hooks/useQueryParams'
import axios from 'axios'
import { getEventsAPIUrl } from '../../utils/apiUtils'
import Bugsnag from '@bugsnag/js'

function useAffiliateTracking() {
  const { aid = '1000' } = useQueryParams()
  useEffect(() => {
    axios
      .post(getEventsAPIUrl(), {
        action: 'set-affliate',
        aid,
      })
      .catch(Bugsnag.notify)
  }, [aid])
}

const PageWrapper = ({ children }) => {
  useAffiliateTracking()
  return children
}

export const wrapPageElement = ({ element, props }) => (
  <PageWrapper {...props}>{element}</PageWrapper>
)
