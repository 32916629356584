import React from 'react'
import { Box, Text } from '@chakra-ui/react'

export default function ErrorScreen() {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      minHeight={'100vh'}
      backgroundColor={'#EFE7D6'}
    >
      <Text fontSize={['24px', '36px']} color={'#030040'} align={'center'}>
        The website has experienced an error!
        <br />
        We apologize for the technical difficulties.
      </Text>
    </Box>
  )
}
